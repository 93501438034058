<template>
    <div class="page">
        <van-nav-bar title="修改密码" left-text="返回" left-arrow @click-left="back"></van-nav-bar>

        <van-form class="edit_form" @submit="setPassWord">
            <van-field class="form_input" v-model="form.originPassword" name="originPassword" type="password" label="旧密码" placeholder="请输入旧密码" :rules="rules.originPassword" />
            <van-field class="form_input" v-model="form.newPassword" name="newPassword" type="password" label="新密码" placeholder="请输入新密码" :rules="rules.newPassword" />
            <div class="form_btn" style="margin: 16px;">
                <van-button round block type="info" native-type="submit"> 提交 </van-button>
            </div>
        </van-form>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                form: {
                    originPassword: '',
                    newPassword: '',
                },
                rules: {
                    originPassword: [{ required: true, message: '请输入旧密码' }],
                    newPassword: [{ required: true, message: '请输入新密码' }],
                }
            };
        },
        methods: {
            back() {
                this.$back();
            },

            setPassWord() {
                const toast = this.$toast.loading({
                    forbidClick: true,
                    duration: 0
                });
                this.$axios.post("/user/editPassword", this.form)
                .then(res => {
                    if(res.code == "ok") {
                        toast.clear();
                        this.$toast.success('修改成功,请重新登录');
                        this.$store.commit("updateLogin", false);
                        sessionStorage.removeItem("userInfo");
                        this.$router.push("/login");
                    } else {
                        toast.clear();
                        this.$toast.fail('修改失败');
                    }
                }).catch(err=> {toast.clear();});
            }
        }
    };
</script>

<style lang="less" scoped>
    .logout {
        bottom: 0;
        height: 50px;
        color: #ff5f16;
    }

    /* 本页公共样式 */
    .gray {
        color: #797d82;
    }

    .bott0 {
        bottom: 0;
    }

    .orange {
        color: #ff5f16
    }

    .f15 {
        font-size: 15px;
    }

    .bd-gray {
        border-bottom: 1px solid #f5f5f5;
    }

    // 设置
    header {
        .city {
            height: 44px;
            line-height: 44px;
        }
    }

    // 账号ID
    .id {
        height: 49px;
        line-height: 49px;

    }
</style>
<style lang="less">
    .van-nav-bar .van-icon , .van-nav-bar__text {
        color: #20a0ff;
    }
</style>

